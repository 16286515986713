import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaTemplateMessageComponent } from './wa-template-message/wa-template-message.component';
import { WaTemplateSelectComponent } from './wa-template-select/wa-template-select.component';


const routes: Routes = [
  { path: 'select', component: WaTemplateSelectComponent, },
  { path: 'message', component: WaTemplateMessageComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaTemplateRoutingModule { }
