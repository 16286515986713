import { NgModule } from "@angular/core";
import { TrendCloudAPIService } from "@trendbuild/trend-cloud-api";
import { ChannelService, SharedModule } from "lib-trend-core";
import { WaTemplateMessageComponent } from "./wa-template-message/wa-template-message.component";
import { WaTemplateRoutingModule } from "./wa-template-routing.module";
import { WaTemplateSelectComponent } from "./wa-template-select/wa-template-select.component";


@NgModule({
  declarations: [
    WaTemplateSelectComponent,
    WaTemplateMessageComponent
  ],
  exports: [
    WaTemplateSelectComponent,
    WaTemplateMessageComponent
  ],
  imports: [
    SharedModule,
    WaTemplateRoutingModule,
  ],
  providers: [
    ChannelService,
    TrendCloudAPIService
  ]
})
export class WaTemplateModule { }
