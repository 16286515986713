import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateRequest, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Channel, ChannelService, Pager, WaTemplateResponse } from 'lib-trend-core';
import { Observable } from 'rxjs';

export interface TemplateMessage {
  previewText: string;
  params: any[];
  imageFile: string;
}

@Component({
  selector: 'wa-template-select',
  templateUrl: './wa-template-select.component.html',
  styleUrls: ['./wa-template-select.component.scss']
})
export class WaTemplateSelectComponent extends AbstractComponent implements OnInit {
  pager: Pager<WaTemplateResponse> = new Pager<WaTemplateResponse>({ perPage: 4 });
  listObservable: Observable<Pager<WaTemplateResponse>>;

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
    this.listWaTemplates();
  }

  @Input() set templateId(templateId: string) {
    this.initTemplate(templateId);
  }

  @Output() onSelectTemplate = new EventEmitter<WaTemplateResponse>();

  selectedTemplate: WaTemplateResponse | null = null;
  waTemplates: WaTemplateResponse[] = [];
  selectedChannel: Channel;
  form: FormGroup;

  loadPage(page: number) {
    this.pager.page = page;
    this.listWaTemplates();
  }

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    public trendCloudAPIService: TrendCloudAPIService,
    public override formBuilder: FormBuilder,
  ) {
    super(injector);
    this.form = this.formBuilder.group({});
  }

  async initTemplate(templateId) {
    await this.listWaTemplates();
    if (this.waTemplates) {
      const template = this.waTemplates.find(wat => wat.id === templateId);
      if (template) {
        this.onSelectTemplate.emit(template);
      }
    }
  }

  ngOnInit(): void {
    this.pager = {
      page: 1,
      perPage: 4,
      previousPage: 0,
      total: 0,
      list: []
    };
    this.listWaTemplates();
  }

  async listWaTemplates() {
    if (!this.selectedChannel) return;

    const templateRequest: TemplateRequest = {
      token: this.selectedChannel.metadata.token,
      whatsAppBusinessAccountID: +this.selectedChannel.metadata.whatsAppBusinessAccountID,
      phoneNumberID: +this.selectedChannel.metadata.phoneNumberID,
      fields: [],
      limit: 1000,
      status: ''
    };

    const response = await this.trendCloudAPIService.getTemplates(templateRequest);
    const allTemplates = (response as any).data || [];

    this.pager.total = (response as any).total || allTemplates.length;

    const start = (this.pager.page - 1) * this.pager.perPage;
    const end = start + this.pager.perPage;
    this.waTemplates = allTemplates.slice(start, end);

    this.waTemplates.forEach(wat => {
      wat.headerComponent = wat.components.find(c => c.type === 'HEADER');
      wat.bodyComponent = wat.components.find(c => c.type === 'BODY');
      wat.footerComponent = wat.components.find(c => c.type === 'FOOTER');
      wat.buttonsComponent = wat.components.find(c => c.type === 'BUTTONS');
    });
  }

  showTemplateDetails(template: WaTemplateResponse) {
    this.onSelectTemplate.emit(template);
  }

  setupForm() {
    this.form = this.formBuilder.group({});
  }

  selectVarContactName(index: number) {
    this.form.get(`var${index}`).setValue('Nome do Contato');
  }

  selectVarLinkChannel(index: number) {
    this.form.get(`var${index}`).setValue('Link canal Whatsapp');
  }

  selectVarOperatorName(index: number) {
    this.form.get(`var${index}`).setValue('Nome atendente');
  }

  selectVarDepartmentName(index: number) {
    this.form.get(`var${index}`).setValue('Departamento');
  }

  selectVarProtocol(index: number) {
    this.form.get(`var${index}`).setValue('Nº do protocolo');
  }
}
