<div style="max-height: 430px; overflow-y: auto;" class="wa-template-message-container">

  <div class="wa-container">
    <div class="wa-image">
      <label for="dropzone-file"
        class="flex flex-col items-center justify-center w-full h-42 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white">
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
          <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#E6E6FF" />
            <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F1F1FF" stroke-width="8" />
            <g clip-path="url(#clip0_6074_436)">
              <path
                d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3"
                stroke="#4213F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_6074_436">
                <rect width="24" height="24" fill="white" transform="translate(16.5 16)" />
              </clipPath>
            </defs>
          </svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
              class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span> ou arraste e solte</p>
          <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG ou JPEG (MAX. 800x400px)</p>
        </div>
        <input id="dropzone-file" type="file" class="hidden" (change)="onSelectImagem($event)" />
      </label>
    </div>
    <div class="wa-text">
      <p>{{previewText}}</p>
    </div>
    <div class="wa-link">
      <p>{{selectedTemplate?.footerComponent?.text}}</p>
    </div>
  
    @if (selectedTemplate?.buttonsComponent){
    <div class="wa-buttons">
      @for (button of selectedTemplate?.buttonsComponent?.buttons; track $index) {
      <div class="wa-button">
        {{button.text}}
      </div>
      }
    </div>
    }
  </div>
</div>


<form [formGroup]="form">
  <div class="flex gap-3 border-t-2 border-gray-200 pt-3 mb-3 ws-variables">
    @for (variable of templateVariableList; track $index) {
    <div class="flex flex-col w-[70%]">
      <label class="text-black mb-2">Corpo variável {{$index + 1}}</label>
      <div class="variable-input">
        <input class="input-template" type="text" [formControlName]="'var' + ($index + 1)" placeholder="Nome">

      </div>
    </div>
    <div class="flex flex-col w-[29%]">
      <button [matMenuTriggerFor]="menuVar" class="var-button">
        Variaveis
      </button>
      <mat-menu #menuVar="matMenu">
        <button mat-menu-item (click)="selectVarGreeting($index + 1)">Saudação</button>
        <button mat-menu-item (click)="selectVarContactName($index + 1)">Nome do Contato</button>
        <button mat-menu-item (click)="selectVarLinkChannel($index + 1)">Link canal Whatsapp</button>
        <button mat-menu-item (click)="selectVarOperatorName($index + 1)">Nome atendente</button>
        <button mat-menu-item (click)="selectVarDepartmentName($index + 1)">Departamento</button>
        <button mat-menu-item (click)="selectVarProtocol($index + 1)">Nº do protocolo</button>
      </mat-menu>
    </div>
    }
  </div>
</form>
